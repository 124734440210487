import httpClient from './httpClient';
const prefix = 'admin'

export default {
  async login(username, password) {
    const { data: token } = await httpClient({ requiresAuth: false }).post('/login-admin', { nickname: username, password })
    if (token) {
      localStorage.setItem('admin', token.token)
      return true
    }
  },
  async profile() {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'admin' }).get(prefix+'/profile')

    return data
  },
  async get_income() {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'admin' }).get(prefix+'/income')

    return data
  },
  async create_user(userObj) {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'admin' }).post(prefix+'/create-user', userObj)

    return data
  },
  async create_agent(agentObj) {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'admin' }).post(prefix+'/create-agent', agentObj)

    return data
  },
  async create_staff(staffObj) {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'admin' }).post(prefix+'/create-staff', staffObj)

    return data
  },
  async set_main_account(id) {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'admin' }).put(prefix+'/set-main-account', { id })

    return data
  },
}